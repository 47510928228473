var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "imgCode",
    },
    [
      _vm.codeUrl
        ? _c(
            "div",
            { staticClass: "code" },
            [
              _c("el-image", {
                staticClass: "wx_code",
                attrs: { src: _vm.codeUrl },
              }),
              _vm._m(0),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "code_text" }, [
      _c("div", [_vm._v("请用微信扫描二维码")]),
      _c("div", [_vm._v("授权推送飞房等相关信息")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }