<template>
  <div class="imgCode" v-loading="loading">
    <div class="code" v-if="codeUrl">
      <!-- <div id="qrcode" ref="qrcode" class="wx_code"></div> -->
      <!-- <img :src="codeUrl" lazy alt class="wx_code" /> -->
      <el-image  class="wx_code" :src="codeUrl"></el-image>
      <div class="code_text">
        <div>请用微信扫描二维码</div>
        <div>授权推送飞房等相关信息</div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
// import flv from "@/assets/flvjs/flv";
import { getQrCode } from "@/api";

export default {
  name: "pushSet",

  data() {
    return {
      codeUrl: null,
      loading: false,
    };
  },
  mounted() {
    // this.$nextTick(() => {
    // this.qrcode();
    // })
  },
  created() {
    this.getCodeImg();
  },
  methods: {
    getCodeImg() {
      this.loading = true;
      getQrCode()
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.data.code == "000000") {
            this.codeUrl = res.data.data;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    qrcode() {
      let qrcode = new QRCode("qrcode", {
        width: 250, // 设置宽度，单位像素
        height: 250, // 设置高度，单位像素
        text: "https://www.baidu.com", // 设置二维码内容或跳转地址
      });
    },
  },
};
</script>

<style scoped lang="scss">
.imgCode {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #ccc;
  border-radius: 10px;
}
.box-card {
  width: 100%;
  height: 100%;
}
.code {
  width: 250px;
  background-color: #fff;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  .wx_code {
    width: 250px;
    height: 250px;
  }
  .code_text {
    // width: 100%;
    padding: 0 20px;
    line-height: 22px;
    text-align: center;
  }
}
</style>
